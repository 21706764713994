import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';
import { currentMonthDateRange } from "../helpers/common";
const initialState = {
  allData: null,
  allRequest:null,
  leavetype: null,
  search: '',
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  searchFilter:{
    startDate: currentMonthDateRange().startDate,
    endDate: currentMonthDateRange().endDate,
      leaveType: "",
      name: ""
  }
};

const leaveWFHRequestSlice = createSlice({
  name: 'leaveWFH',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
        if(action.payload.type === 'leave-type'){
            state.leavetype = action.payload.data;
        }else if(action.payload.type === 'leave'){
            state.allData = action.payload.data;
            state.pagination = action.payload.pagination;
        }else if(action.payload.type === 'leave-request'){
          state.allRequest = action.payload.data;
          state.pagination = action.payload.pagination;
      }
        state.loading = false;
    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    added: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setFilter: (state, action) => {
      const { key, value } = action.payload; 
      console.log(key, value)
      state.searchFilter[key] = value; 
    },
    resetFilters: (state) => {
      state.searchFilter = {
        startDate: currentMonthDateRange().startDate,
        endDate: currentMonthDateRange().endDate,
        status: "all",
        name: "",
      };
    },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  added,
  removeErrorMsg,
  setFilter,
  resetFilters
} = leaveWFHRequestSlice.actions;

export default leaveWFHRequestSlice.reducer;

export const getAll = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`/my-leave-wfh?page=${page}&per_page=${per_page}&search=${search}&fields[]=name&fields[]=description`);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination , type:'leave'}));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const getAllRequest = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().leaveWFH;
  console.log(searchFilter, 'searchFilter');
  try {
    const response = await axiosInstance.post(`/my-leave-wfh-request?page=${page}&per_page=${per_page}`, searchFilter);
    const { data, status, message, pagination } = response.data;
    if (status) {
            dispatch(requestSuccess({ data, pagination , type:'leave-request' }));
          } else {
            dispatch(requestFailure(message || 'Failed to fetch data'));
          }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getLeaveType = () => async (dispatch) => {
    dispatch(requestStart());
    try {  
      const response = await axiosInstance.get(`/all-leave-types`);
      const { data, status, message } = response.data;
      if (status) {
        dispatch(requestSuccess({ data, type:'leave-type'}));
      } else {
        dispatch(requestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
      dispatch(requestFailure(errorMessage));
    }
  };

export const add = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/new-request`, newRequest);
    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add request'));
    }
  } catch (error) {

    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(requestFailure(errorMessage || 'Failed to add request'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};

export const updateStatus = (newRequest) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.post(`/update-request-status`, newRequest);
    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add request'));
    }
  } catch (error) {

    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages

      dispatch(requestFailure(errorMessage || 'Failed to add request'));
    } else {
      const errorMessage = error.response?.data?.message || error.message || 'Failed to add grade';
      dispatch(requestFailure(errorMessage));
    }
  }
};



export const deleteRequest = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.delete(`/delete-my-request/${id}}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to delete request'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to delete data';
    dispatch(requestFailure(errorMessage));
  }
};



