import React, { useEffect, useState } from "react";
import axios from "axios";
import Charts from "./charts";
import Reports from "./Reports";
import Statistics from "./statistics";
import InvoiceTable from "./invoiceTable";
import PaymentTable from "./paymentTable";
import ClientTable from "./clientTable";
import RecentTable from "./recentTable";
import Breadcrumbs from "../../Breadcrumbs";
import { base_url } from "../../../base_urls";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardStats } from "../../../redux/commonSlice";
import { Loader } from "react-feather";
import { Link } from "react-router-dom";
import LeaveStats from "./components/LeaveStats";
import Chart from "react-apexcharts";
import {
    avatar19,
    holidaycalendar,
} from "../../../../src/Routes/ImagePath/index.jsx";
import Slider from "react-slick";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import Chart from "react-apexcharts";
const AdminDashboard = () => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { dashboardstats, loading, profile } = useSelector(
        (state) => state.common
    );

    const getUrl = (path) => {
        return path.includes("/api") ? path.replace("/api", "/storage") : path;
    };

    const [profilePic, setProfilePic] = useState("");
    const [profileName, setProfileName] = useState(profile?.name || "");
    const [holidayName, setHolidayName] = useState("");
    const [holidayDate, setHolidayDate] = useState("");
    const [leaveStats, setLeaveStats] = useState([]);
    const [totalWorkingDays, setTotalWorkingDays] = useState(0);
    const [hoursReportType, setHoursReportType] = useState("current_week");
    const [workingHours, setWorkingHours] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: "Reported Hours",
                data: [], // Positive values for above zero.
            },
            {
                name: "Missing Hours",
                data: [], // Negative values for below zero.
            },
        ],
        colors: ["#55CE63", "#FC133D"],
        chart: {
            type: "bar",
            height: 210,
            stacked: true,
            zoom: {
                enabled: true,
            },
        },
        responsive: [
            {
                breakpoint: 280,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 6,
                columnWidth: "30%",
                endingShape: "rounded", // Valid property for some charting libraries.
            },
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: {
            min: -8,
            max: 8,
            tickAmount: 4, // Adjusted for better visibility of ticks.
        },
        xaxis: {
            categories: ["M", "T", "W", "T", "F", "S", "S"],
        },
        legend: {
            show: false,
        },
        fill: {
            opacity: 1,
        },
    });
    const [projects, setProjects] = useState([]);
    const [slideCount, setSlideCount] = useState([]);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const nextYear = currentYear + 1;

    // Check if the current date is after March 31st to determine session year
    const isAfterApril = currentDate.getMonth() >= 3; // After March (month 3, meaning April onwards)

    // Determine the session year range
    const startYear = isAfterApril ? currentYear : currentYear - 1;
    const endYear = isAfterApril ? nextYear : currentYear;

    const [selectedSession, setSelectedSession] = useState(
        `${startYear}-${endYear}`
    );

    // Create an array for the past, current, and next sessions
    const pastSessions = [
        `${startYear - 1}-${endYear - 1}`, // Previous session
        `${startYear}-${endYear}`, // Current session
        `${startYear + 1}-${endYear + 1}`, // Next session
    ];

    const handleSessionChange = (session) => {
        setSelectedSession(session);
    };

    const handleHoursReportTypeChange = (type) => {
        setHoursReportType(type);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            weekday: "short",
            day: "2-digit",
            month: "short",
            year: "numeric",
        };
        return date.toLocaleDateString("en-GB", options);
    };

    const getWeekDates = (reportType) => {
        const startOfWeek =
            reportType === "current_week"
                ? new Date()
                : new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // Adjust for past week

        // Adjust to Monday (instead of Sunday)
        const dayOfWeek = startOfWeek.getDay();
        const difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // If Sunday (0), set to 6, else subtract 1
        startOfWeek.setDate(startOfWeek.getDate() - difference);

        return Array.from({ length: 7 }, (_, i) => {
            const date = new Date(startOfWeek);
            date.setDate(date.getDate() + i);
            return date.toISOString().split("T")[0]; // Format YYYY-MM-DD
        });
    };

    const processData = (workingHours, reportType) => {
        const weekDates = getWeekDates(reportType); // Get all dates for the week
        const reportedHours = [];
        const missingHours = [];
        // console.log(weekDates);
        weekDates.forEach((date) => {
            const entry = workingHours[date];
            // Check if it's Saturday
            const isSaturday = new Date(date).getDay() === 6;
            if (entry) {
                // Handle specific conditions
                if (entry.leave === "yes" || entry.holiday === "yes") {
                    reportedHours.push(0);
                    missingHours.push(0);
                } else {
                    if (isSaturday && entry.saturdayWorking === "no") {
                        // Set reported hour 0 and missing hour 0 if it's Saturday and `saturdayWorking` is "no"
                        reportedHours.push(0);
                        missingHours.push(0);
                    } else {
                        const reported = entry.dailyReportedHours || 0;
                        const missing = 8 - reported; // Assuming a full day is 8 hours.
                        reportedHours.push(reported);
                        missingHours.push(missing > 0 ? -missing : 0);
                    }
                }
            } else {
                reportedHours.push(0);
                missingHours.push(0);
            }
        });

        return [
            {
                name: "Reported Hours",
                data: reportedHours,
            },
            {
                name: "Missing Hours",
                data: missingHours,
            },
        ];
    };

    useEffect(() => {
        if (profile) {
            const profilePicUrl = getUrl(
                `${process.env.REACT_APP_API_URL}/${profile?.profile_pic}`
            );
            setProfilePic(profilePicUrl ? profilePicUrl : "");
            setProfileName(profile?.name);
        }
    }, [profile]);

    useEffect(() => {
        const processedData = processData(workingHours, hoursReportType);
        setChartOptions((prevState) => ({
            ...prevState,
            series: processedData,
        }));
    }, [workingHours, setChartOptions, hoursReportType]);

    useEffect(() => {
        console.log("dashboardstats:", dashboardstats);
        if (dashboardstats) {
            const upcomingHolidays = dashboardstats?.upcomingHolidays || [];
            // Check if there is at least one holiday in the array
            if (upcomingHolidays.length > 0) {
                setHolidayName(
                    upcomingHolidays[0].holiday_name || "No Name Provided"
                );
                const formattedDate = formatDate(
                    upcomingHolidays[0].holiday_date || "N/A"
                );
                setHolidayDate(formattedDate);
                // console.log("Next holiday:", upcomingHolidays[0].holiday_name, formattedDate);
            } else {
                setHolidayName("No Upcoming Holidays");
                //  console.log("No upcoming holidays available.");
            }

            if (dashboardstats?.leaveStats)
                setLeaveStats(dashboardstats?.leaveStats);
            if (dashboardstats?.totalWorkingDayInYear)
                setTotalWorkingDays(dashboardstats?.totalWorkingDayInYear);
            if (dashboardstats?.session)
                setSelectedSession(dashboardstats.session);
            if (dashboardstats?.workingHours)
                setWorkingHours(dashboardstats.workingHours);
            if (dashboardstats?.projects) setProjects(dashboardstats.projects);
            if(dashboardstats?.reportType)
            setHoursReportType(dashboardstats?.reportType);
        }
    }, [dashboardstats]);

    useEffect(() => {
        //console.log(projects);
        if (projects.length > 0) setSlideCount(projects.length);
        else setSlideCount(0);
    }, [projects]);

    useEffect(() => {
        dispatch(getDashboardStats(selectedSession, hoursReportType));
    }, [dispatch, selectedSession, hoursReportType]);

    // const slideCount = 1;
    // Adjust slider settings based on the number of slides
    const settingsprojectslide = {
        dots: false, // Show dots only if more than 1 slide
        infinite: slideCount > 3, // Enable infinite loop only if more than 1 slide
        speed: 500,
        slidesToShow: slideCount > 0 ? Math.min(slideCount, 3) : 1,
        //slidesToShow: Math.min(3, slideCount), // Show as many slides as available, up to 3
        slidesToScroll: 1, // Scroll one slide at a time
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: Math.min(2, slideCount), // Show up to 2 slides on smaller screens
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1, // Show 1 slide on very small screens
                },
            },
        ],
    };
    useEffect(() => {
        dispatch(getDashboardStats());
    }, [dispatch]);

    return (
        <div className="main-wrapper">
            <div className="page-wrapper">
                <div className="content container-fluid  pb-0">
                    {/* Page Header */}
                    <Breadcrumbs title="Dashboard" />

                    {/* /Page Header */}
                    <div className="row">
                        {loading ? (
                            <Loader
                                className="feather-loader"
                                data-bs-toggle="tooltip"
                                title="Loading..."
                            />
                        ) : Array.isArray(dashboardstats) &&
                          dashboardstats.length > 0 ? (
                            dashboardstats?.map((item, index) => (
                                <div
                                    className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                                    key={index}
                                >
                                    <div className="card dash-widget">
                                        <div className="card-body">
                                            <span
                                                className={`dash-widget-icon ${item.icon}`}
                                            />
                                            <div className="dash-widget-info">
                                                <h3>{item.number}</h3>
                                                <span>{item.label}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            profile?.role_id === 1 && profile?.role_id === 2 ?
                            <p>No data available</p>:""
                        )}
                    </div>

                    {profile?.role_id !== 1 && profile?.role_id !== 2 ? (
                    <div className="row mt-2">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="row">
                                {/* Employee Details */}
                                <div className="col-lg-4 col-md-12 d-flex flex-column">
                                    <div className="card employee-welcome-card flex-fill overflow-hidden">
                                        <div className="card-body d-flex flex-column align-items-stretch justify-content-between">
                                            <div className="welcome-info">
                                                <div className="welcome-content">
                                                    <h4>
                                                        Welcome Back,{" "}
                                                        {profileName}
                                                    </h4>
                                                </div>
                                                <div className="welcome-img">
                                                    <img
                                                        src={
                                                            profilePic ||
                                                            avatar19
                                                        }
                                                        className="img-fluid"
                                                        alt="User"
                                                        onError={(e) => {
                                                            e.target.onerror =
                                                                null; // Avoid infinite loop
                                                            e.target.src =
                                                                avatar19; // Fallback image
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="welcome-btn">
                                                <Link
                                                    to="/profile"
                                                    className="btn"
                                                >
                                                    View Profile
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card info-card flex-fill">
                                        <div className="card-body d-flex flex-column align-items-stretch justify-content-between">
                                            <h4>Upcoming Holidays</h4>
                                            <div className="holiday-details">
                                                <div className="holiday-calendar">
                                                    <div className="holiday-calendar-icon">
                                                        <img
                                                            src={
                                                                holidaycalendar
                                                            }
                                                            alt="Icon"
                                                        />
                                                    </div>
                                                    <div className="holiday-calendar-content">
                                                        <h6>{holidayName}</h6>
                                                        <p>{holidayDate}</p>
                                                    </div>
                                                </div>
                                                <div className="holiday-btn">
                                                    <Link
                                                        to="/my-attendance"
                                                        className="btn"
                                                    >
                                                        View All
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 d-flex flex-column">
                                    {/* Attendance & Leaves */}
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="statistic-header">
                                                <h4>Leaves &amp; WFH</h4>
                                                <div className="dropdown statistic-dropdown">
                                                    <Link
                                                        className="dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        to="#"
                                                    >
                                                        {selectedSession}
                                                    </Link>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        {pastSessions.map(
                                                            (session) => (
                                                                <Link
                                                                    key={
                                                                        session
                                                                    }
                                                                    to="#"
                                                                    className="dropdown-item"
                                                                    onClick={() =>
                                                                        handleSessionChange(
                                                                            session
                                                                        )
                                                                    } // Set the selected year when clicked
                                                                >
                                                                    {session}
                                                                </Link>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="attendance-list">
                                                <div className="row">
                                                    <LeaveStats
                                                        leaveStats={leaveStats}
                                                        workingDays={
                                                            totalWorkingDays
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="view-attendance">
                                                <Link to="/leave-wfh">
                                                    Apply Leave
                                                    <i className="fe fe-arrow-right-circle" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /Attendance & Leaves */}
                                </div>
                                <div className="col-lg-4 col-md-12 d-flex flex-column">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="statistic-header">
                                                <h4>Working hours</h4>
                                                <div className="dropdown statistic-dropdown">
                                                    <Link
                                                        className="dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        to="#"
                                                    >
                                                        {hoursReportType ===
                                                        "current_week"
                                                            ? "Current Week"
                                                            : "Last Week"}
                                                    </Link>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        {[
                                                            "current_week",
                                                            "last_week",
                                                        ].map((reportType) => (
                                                            <Link
                                                                key={reportType}
                                                                to="#"
                                                                className="dropdown-item"
                                                                onClick={() =>
                                                                    handleHoursReportTypeChange(
                                                                        reportType
                                                                    )
                                                                }
                                                            >
                                                                {reportType ===
                                                                "current_week"
                                                                    ? "Current Week"
                                                                    : "Last Week"}
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="working-hour-info">
                                                <div id="working_chart" />
                                                <Chart
                                                    options={chartOptions}
                                                    series={chartOptions.series}
                                                    type="bar"
                                                    height={240}
                                                />
                                            </div>
                                            <hr></hr>
                                            <div className="view-attendance">
                                                <Link to="/project/my-projects">
                                                    Log Hours
                                                    <i className="fe fe-arrow-right-circle" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : "" }
                    {profile?.role_id !== 1 && profile?.role_id !== 2 ? (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-sm-8">
                                            <div className="statistic-header">
                                                <h4>On Going Projects</h4>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 text-sm-end">
                                            <div className="owl-nav project-nav nav-control" />
                                        </div>
                                    </div>
                                    <Slider
                                        // key={projects?.length}
                                        {...settingsprojectslide}
                                        className="project-slider owl-carousel"
                                    >
                                        {(Array.isArray(projects)
                                            ? projects
                                            : []
                                        ).map((project) => (
                                            <div
                                                className="project-grid"
                                                key={project.id}
                                            >
                                                <div className="project-top">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className="project-start-date">
                                                                <Link to="#">
                                                                    StartDate :{" "}
                                                                    {new Date(
                                                                        project.start_date
                                                                    ).toLocaleDateString()}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6>
                                                                <Link to="#">
                                                                    Deadline:{" "}
                                                                    {new Date(
                                                                        project.end_date
                                                                    ).toLocaleDateString()}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                    </div>

                                                    <h5 className="mt-2">
                                                        <Link to="#">
                                                            {
                                                                project.project_name
                                                            }
                                                        </Link>
                                                    </h5>
                                                    <p>{project.description}</p>
                                                </div>
                                                <div className="project-middle">
                                                    <div className="project-tasks">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <p>
                                                                    Worked Hours
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h4>
                                                                    {/* Replace with dynamic completed tasks */}
                                                                    {
                                                                        project.total_hours
                                                                    }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-bottom">
                                                    <div className="project-leader">
                                                        <ul className="nav">
                                                            <li>
                                                                Project Leader:
                                                            </li>
                                                            <li>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {
                                                                                project
                                                                                    .manager
                                                                                    .name
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Link
                                                                        to="#"
                                                                        data-bs-toggle="tooltip"
                                                                        aria-label={
                                                                            project
                                                                                .manager
                                                                                .name
                                                                        }
                                                                        data-bs-original-title={
                                                                            project
                                                                                .manager
                                                                                .name
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                project
                                                                                    .manager
                                                                                    .profile_pic ||
                                                                                avatar19
                                                                            }
                                                                            alt={
                                                                                project
                                                                                    .manager
                                                                                    .name
                                                                            }
                                                                            onError={(
                                                                                e
                                                                            ) => {
                                                                                e.target.onerror =
                                                                                    null; // Avoid infinite loop
                                                                                e.target.src =
                                                                                    avatar19; // Fallback image
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="project-leader">
                                                        <ul
                                                            key={project.id}
                                                            className="nav"
                                                        >
                                                            <li>Members:</li>
                                                            {Object.values(
                                                                project.project_employee
                                                            )
                                                                .slice(0, 5)
                                                                .map(
                                                                    (
                                                                        employee,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            key={`${project.id}-${employee.id}-${index}`}
                                                                        >
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        {
                                                                                            employee.name
                                                                                        }
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Link to="#">
                                                                                    <img
                                                                                        src={
                                                                                            employee.profile_pic ||
                                                                                            avatar19
                                                                                        }
                                                                                        alt={
                                                                                            employee.name
                                                                                        }
                                                                                        onError={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.target.onerror =
                                                                                                null; // Avoid infinite loop
                                                                                            e.target.src =
                                                                                                avatar19; // Fallback image
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        </li>
                                                                    )
                                                                )}

                                                            {Object.values(
                                                                project.project_employee
                                                            ).length > 5 && (
                                                                <li>
                                                                    <Link
                                                                        to="#"
                                                                        className="more-team-members"
                                                                    >
                                                                        {`+${
                                                                            Object.values(
                                                                                project.project_employee
                                                                            )
                                                                                .length -
                                                                            5
                                                                        }`}
                                                                    </Link>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : "" }

                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
