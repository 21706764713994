import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';
import { currentMonthDateRange } from "../helpers/common";
import { employmentType } from '../helpers/Constants';

const initialState = {
  projectWiseData:null,
  filter:null,
  pagination: null,
  loading: false,
  error: null,
  success: null,
  message: null,
  monthList:null,
  monthListPagination:null,

  timeSheetData:null,
  hasActivityTask:false,
  timeSheetPagination:null,

  hourlyTimeSheetData:null,
  hourlyTimeSheetPagination:null,
  // attendance
  attendanceData:null,
  attendancePagination:null,

// leave-details
  leaveData:null,
  leavePagination:null,
  reportStats:null,

  missingListAttendance:null,

  // EMployeeAttendance
  employeeAttendanceDetails : null,
  employeeAttendancePagination : null,

  searchFilter:{
    team_id: "",
    manager_id: "",
    grade_id: "",
    workModel: "",
    employmentType:"",
    project_id: "",
    employee_id: "",
    startDate:  currentMonthDateRange().startDate,
    endDate: currentMonthDateRange().endDate,
    type:'search',
    searchType:'',
  },
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      if(action.payload.type === 'project-wise'){
        state.projectWiseData  = action.payload.data; 
        state.pagination = action.payload.pagination; 
      }else if(action.payload.type === 'filters'){
        state.filter  = action.payload.data;
      }else if(action.payload.type === 'monthList'){
        state.monthList= action.payload.data;
        state.monthListPagination=action.payload.pagination;
      }else if(action.payload.type==="time-sheet-details"){
        state.timeSheetData = action.payload.data.data.data;
        state.timeSheetPagination = action.payload.data.pagination;
        state.hasActivityTask = action.payload.data.hasActivityTask
      }else if(action.payload.type==="hourly-wise"){
        state.hourlyTimeSheetData = action.payload.data;
        state.hourlyTimeSheetPagination = action.payload.pagination;
      }else if(action.payload.type==="attendance"){
        state.attendanceData = action.payload.data;
        state.attendancePagination = action.payload.pagination;
      }else if(action.payload.type=== 'leave-details'){
        state.leaveData = action.payload.data;
        state.leavePagination = action.payload.pagination;
      }else if(action.payload.type=== 'stats'){
        state.reportStats = action.payload.data;
      }else if(action.payload.type=== 'unmarked-user'){
        state.missingListAttendance = action.payload.data;
      }else if(action.payload.type=== 'employee-attendance-details'){
        state.employeeAttendanceDetails = action.payload.data;

      }
      state.loading = false;
    },
    requestFailure: (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = null;
    },
    removeErrorMsg: (state) => {
        state.error = null;
        state.success = null;
        state.message = null;
    },
    
    setFilter: (state, action) => {
      const { key, value } = action.payload; 
      console.log(key, value)
      state.searchFilter[key] = value; 
    },
    resetFilters: (state) => {
      state.searchFilter = {
        startDate: currentMonthDateRange().startDate,
        endDate: currentMonthDateRange().endDate,
        status: "all",
        name: "",
      };
    },
    requestCompleted :  (state) => {
      state.error = null;
      state.success = null;
      state.message = null;
      state.loading = false;
  },
  },
});

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  removeErrorMsg,
  setFilter,
  resetFilters,
  requestCompleted
} = reportSlice.actions;

export default reportSlice.reducer;


export const getProjectTimeSheet = ({ page, per_page, search }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/project-time-sheet?page=${page}&per_page=${per_page}`, searchFilter);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'project-wise' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const getUnMarkedUsers = (type) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/get-missing-attendance-list`, {...searchFilter, listType:type});
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'unmarked-user' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const getFiltersData = () => async (dispatch) => {
  dispatch(requestStart());
  try {

    const response = await axiosInstance.get(`/project-filters`);
    const { data, status, message } = response.data;
    console.log(data);
    if (status) {
      dispatch(requestSuccess({ data, type:'filters' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const getMonthListData = (emp_id, pro_id) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/get-month-list`, 
      {
        projectId:pro_id ,
        empId:emp_id,
        start_date:searchFilter.startDate,
        end_date:searchFilter.endDate
      });
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'monthList' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }

    dispatch(requestFailure('message' || 'Failed to fetch data'));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};


export const getTimeSheetDetails = ({ page, per_page, date, employee_id, project_id}) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/get-time-sheet-details`, 
      {
        project_id,
        employee_id,
        date,
        per_page:per_page,
        page
      });

      console.log(response.data.data, '--')
      const { data, status ,message} = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'time-sheet-details' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }

    dispatch(requestFailure('message' || 'Failed to fetch data'));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const downloadTimeSheetExcel = ({date, employee_id, project_id }) => async (dispatch) => {
  dispatch(requestStart());
  
  try {
    const response = await axiosInstance.get(`/export-user-time-sheet`, {
      params: { date, employee_id, project_id },
      responseType: 'blob',
    });

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'timesheet.xlsx'); // Set the file name
    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up
    dispatch(requestCompleted());

  

  } catch (error) {
    // Handle errors here
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};
export const downloadTimeSheetExcelAll = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;  
  try {
    const response = await axiosInstance.get(`/export-user-time-sheet-all`, {
      params: searchFilter,
      responseType: 'blob',
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'timesheet.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch(requestCompleted());

  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};

export const getHourlyTimeSheet = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/hourly-time-sheet?page=${page}&per_page=${per_page}`, searchFilter);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'hourly-wise' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};
export const getHourlyTimeSheetExport = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;  
  try {
    const response = await axiosInstance.get(`/hourly-time-sheet-export`, {
      params: searchFilter,
      responseType: 'blob',
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'timesheet.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch(setFilter({ key: "type", value: 'search' }))
    dispatch(requestCompleted());

  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};

export const attendanceReport = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/attendance-report?page=${page}&per_page=${per_page}`, searchFilter);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'attendance' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const attendanceReportStats = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/attendance-stats`, searchFilter);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'stats' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const attendanceReportExport = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;  
  try {
    const response = await axiosInstance.get(`/attendance-report-export`, {
      params: searchFilter,
      responseType: 'blob',
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'attendance.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch(setFilter({ key: "type", value: 'search' }))
    dispatch(requestCompleted());

  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};

export const leaveDetails = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/leave-details?page=${page}&per_page=${per_page}`, searchFilter);
    const { data, status, message, pagination } = response.data;
    // dispatch(setFilter({ key: "employee_id", value: "" }))
    if (status) {
      dispatch(requestSuccess({ data,pagination, type:'leave-details' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};

export const leaveDetailsExport = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;  
  try {
    const response = await axiosInstance.get(`/leave-details-export`, {
      params: searchFilter,
      responseType: 'blob',
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'leave-details.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch(setFilter({ key: "type", value: 'search' }))
    dispatch(requestCompleted());

  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};


export const employeeAttendance = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().report;
  try {
    const response = await axiosInstance.post(`/employee-attendance`, searchFilter);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, type:'employee-attendance-details' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch grade';
    dispatch(requestFailure(errorMessage));
  }
};





