import React, { useEffect ,useState} from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addAsset, removeErrorMsg, updateAsset,getAllAssets, getAllAssetCategories } from "../../../../redux/assetSlice";
import { useDispatch, useSelector } from 'react-redux';
import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { customStyles } from "../../../../helpers/Constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format, parseISO } from 'date-fns';



function AddEditAsset({ show, handleClose, asset }) {
    const dispatch = useDispatch();
    const [paginationState, setPaginationState] = useState({
        current: 1,
        pageSize: 1000,
      });

    useEffect(() => {
        dispatch(getAllAssets({ page: paginationState.current, per_page: paginationState.pageSize, search:'' }));
        dispatch(getAllAssetCategories({ page: paginationState.current, per_page: paginationState.pageSize,search:''  }));
      }, [dispatch, paginationState.current, paginationState.pageSize]);

    const { assetCategories,assets = [],
        loading = false,
        error = "", } = useSelector((state) => state.asset);

        const validationSchema = Yup.object().shape({
            owned_by: Yup.string().required("Asset Owned is required"),
            asset_code: Yup.string().required("Asset Code is required")
             .test("unique", "Asset code already exists.", (value) => {
                return !assets.some(
                  (existingAsset) =>
                    existingAsset.asset_code === value && existingAsset.id !== asset?.id
                );
              }),

            asset_type: Yup.string().required("Asset Type is required"),
            brand: Yup.string().required("Brand is required"),

            purchase_date: Yup.string().required("Purchase Date  is required"),
            model: Yup.string().required("Model is required")
            .test("unique", "Asset serial number already exists.", (value) => {
                return !assets.some(
                  (existingAsset) =>
                    existingAsset.model_serial_number === value && existingAsset.id !== asset?.id
                );
              }),

            ram: Yup.string().nullable(),
            processor: Yup.string().nullable(),
            macaddress: Yup.string().nullable(),

            storage: Yup.string().nullable(),
            os: Yup.string().nullable(),

            asset_value: Yup.string().required("Asset price is required"),
        });

    const [isComputer, setIsComputer] = useState(false);

    const assetCategoriesOptions = assetCategories?.map(g => ({
        value: g.id,
        label: g.name,
    }));

    const ownedByOptions = [{
        value: 'company',
        label: 'Company',
    }, {
        value: 'customer',
        label: 'Customer',
    }];

    const osOptions = [{
        value: 'windows',
        label: 'Windows',
    }, {
        value: 'linux',
        label: 'Linux',
    },
    {
        value: 'mac',
        label: 'Mac',
    }, {
        value: 'other',
        label: 'other',
    }];

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            owned_by: "company",
            asset_code: "",
            asset_type: "",
            brand: "",
            purchase_date: "",
            model: "",
            ram: "",
            processor: "",
            macaddress:"",
            storage: "",
            os: "",
            asset_value: "",
            description: "",
        },
    });

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            if (asset) {
                values.id = asset.id;
                await dispatch(updateAsset(values));

            } else {
                await dispatch(addAsset(values));
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (asset) {
            reset({
            owned_by: asset?.owned_by || "company",
            asset_code: asset?.asset_code || "",
            asset_type: asset?.asset_type || "",
            brand: asset?.brand || "",
            purchase_date: asset?.purchase_date || "",
            model: asset?.model || "",
            ram: asset?.ram || "",
            processor: asset?.processor || "",
            macaddress:asset?.macaddress || "",
            storage:  asset?.storage ||"",
            os: asset?.os || "",
            asset_value: asset?.asset_value || "",
            description: asset?.description || "",
            });
            const isComputer = asset?.asset_type || false === 1? true:false;
            setIsComputer(isComputer);

        } else {
            reset({
                owned_by:"company",
                asset_code: "",
                asset_type: "",
                brand: "",
                purchase_date: "",
                model: "",
                ram: "",
                processor: "",
                macaddress: "",
                storage: "",
                os: "",
                asset_value: "",
                description: "",
            });
            setIsComputer(false);
        }

    }, [asset, reset]);


    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal custom-modal-three">
            <Modal.Header>
                <Modal.Title>{asset ? 'Edit Asset' : 'Add Asset'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert
                        type="danger"
                        message={error}
                        onClose={resetMsg}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Asset Owned By:
                                </label>
                                <Controller
                                    name="owned_by"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={ownedByOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={ownedByOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.owned_by ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.owned_by && (
                                    <span className="text-danger">
                                        {errors.owned_by.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Asset Code:
                                </label>
                                <Controller
                                    name="asset_code"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.asset_code ? "error-input" : ""
                                                }`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.asset_code && (
                                    <span className="text-danger">{errors.asset_code.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Type :
                                </label>
                                <Controller
                                    name="asset_type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={assetCategoriesOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={assetCategoriesOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                const value = selectedOption ? selectedOption.value : null;

                                                console.log(selectedOption);
                                                // Update the field value
                                                field.onChange(value);

                                                const label = selectedOption ? selectedOption.label.toLowerCase() : null;

                                                // Check if the selected option corresponds to a computer-related category
                                                if (label === "pc" || label === "laptop" || label === "computer") {
                                                    setIsComputer(true);
                                                } else {
                                                    setIsComputer(false);
                                                }
                                            }}
                                            className={` ${errors.asset_type ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.asset_type && (
                                    <span className="text-danger">
                                        {errors.asset_type.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Brand / Company:
                                </label>
                                <Controller
                                    name="brand"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.brand ? "error-input" : ""
                                                }`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.brand && (
                                    <span className="text-danger">{errors.brand.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Purchase Date :
                                </label>
                                <Controller
                                    name='purchase_date'
                                    control={control}
                                    render={({ field }) => (
                                        <div className="cal-icon">
                                            <DatePicker
                                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                                selected={field.value ? parseISO(field.value) : null}
                                                onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.purchase_date && (
                                    <span className="text-danger">
                                        {errors.purchase_date.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Model/Serial Number :
                                </label>
                                <Controller
                                    name="model"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.model ? "error-input" : ""
                                                }`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.model && (
                                    <span className="text-danger">{errors.model.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {isComputer &&    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Ram :
                                </label>
                                <Controller
                                    name="ram"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.ram ? "error-input" : ""}`}
                                            type="number"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.ram && (
                                    <span className="text-danger">
                                        {errors.ram.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Processor:
                                </label>
                                <Controller
                                    name="processor"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.processor ? "error-input" : ""
                                                }`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.processor && (
                                    <span className="text-danger">{errors.processor.message}</span>
                                )}
                            </div>
                        </div>
                    </div>}

                    {isComputer &&    <div className="row">
                        {/* <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Ram :
                                </label>
                                <Controller
                                    name="ram"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.ram ? "error-input" : ""}`}
                                            type="number"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.ram && (
                                    <span className="text-danger">
                                        {errors.ram.message}
                                    </span>
                                )}
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Mac Address:
                                </label>
                                <Controller
                                    name="macaddress"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.macaddress ? "error-input" : ""
                                                }`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.macaddress && (
                                    <span className="text-danger">{errors.macaddress.message}</span>
                                )}
                            </div>
                        </div>
                    </div>}
                    {isComputer &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Operating System :
                                </label>
                                <Controller
                                    name="os"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={osOptions} // This should be an array of objects with label and value
                                            placeholder="Select"
                                            styles={customStyles}
                                            value={osOptions?.find(option => option.value === field.value) || null} // Set the selected option
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption ? selectedOption.value : null); // Store only the value
                                            }}
                                            className={` ${errors.os ? "error-input" : ""}`}
                                        />
                                    )}
                                />
                                {errors.os && (
                                    <span className="text-danger">
                                        {errors.os.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Storage:
                                </label>
                                <Controller
                                    name="storage"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.storage ? "error-input" : ""
                                                }`}
                                            type="number"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.storage && (
                                    <span className="text-danger">{errors.storage.message}</span>
                                )}
                            </div>
                        </div>
                    </div>}
                    <div className="row">
                    <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Asset Value :
                                </label>
                                <Controller
                                    name="asset_value"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            className={`form-control ${errors.asset_value ? "error-input" : ""
                                                }`}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.asset_value && (
                                    <span className="text-danger">
                                        {errors.asset_value.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-block mb-3">
                                <label className="col-form-label">
                                    Description :
                                </label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                        className={`form-control ${errors.description ? "error-input" : ""}`}
                                        rows="2" // Set an initial height with rows
                                        style={{ resize: "vertical" }} // Allow vertical resizing
                                        {...field}
                                    />
                                    )}
                                />
                                {errors.description && (
                                    <span className="text-danger">{errors.description.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditAsset;
