import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
// import { BrowserRouter, Route, Routes,  } from "react-router-dom";
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import AdminDashboard from './components/admin/dashboard/index';
import UserDashboard from './components/user/dashboard/index';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout/Layout';
import EmployeeList from './components/admin/employee';

import Designation from './components/admin/master/designation';
import Clients from './components/admin/clients';
import ClientForm from './components/admin/clients/Form/ClientForm';

import Templates from './components/admin/pem/templates';
import TemplateForm from './components/admin/pem/templates/Form/TemplateForm';
import AllPem from './components/admin/pem/all-pem';
import AllPemForm from './components/admin/pem/all-pem/Form/TemplateForm';
import AllPemReport from './components/admin/pem/pemreport/ReportForm';

import MyGoalPemForm from './components/admin/pem/mygoals/TemplateForm';
import PemRequestForm from './components/admin/pem/pemrequest/Form/PemRequest';
import PemRequestAppraiserForm from './components/admin/pem/pemrequest/Form/PemRequestAppraiser';


import AssetCategory from './components/admin/assets/assetcategory';
import Assets from './components/admin/assets';
import AssetRequest from './components/admin/assets/assetrequest';
import AssetStats from './components/admin/assets/assetstats';
import Customer from './components/admin/master/customer';
import ProjectTech from './components/admin/master/project_technology';
import ProjectBusinessArea from './components/admin/master/projectBusinessArea';
import ProjectBillability from './components/admin/master/projectBillability';
import ProjectPaymentType from './components/admin/master/projectPaymentType';
import ProjectPartner from './components/admin/master/projectPartner';
import ProjectType from './components/admin/master/projectType';

import Companies from './components/admin/companies';
import CompanyForm from './components/admin/companies/Form/CompanyForm';

import RolesPermissions from './components/admin/settings/RolesPermissions';
import Grade from './components/admin/master/grade';
import Team from './components/admin/master/team';
import PemQuestions from './components/admin/pem/pemquestions';


import PemRequests from './components/admin/pem/pemrequest';
import myPemForm from './components/admin/pem/pemrequest/Form/TemplateForm';

// import Templates from './components/admin/pem/templates';
//import { Bars } from 'react-loader-spinner';




import ContractEmployeeSalary from './components/admin/contractor';
import NotFound from './components/Auth/NotFound';
import ProjectTask from './components/admin/projects/projectTask';
import TimeSheet from './components/admin/reports/Common/TimeSheet';

import Subscription from './components/admin/subscriptions';
import ContractEmployeeList from './components/admin/contractor/contracted-employee';



import Test from './components/test/index';

const AddUser = React.lazy(() => import('./components/admin/employee/AddUser'));

const MyAttendance = React.lazy(() => import('./components/user/attendance/myattendance'));
const Holiday = React.lazy(() => import('./components/admin/master/holiday'));
const Leave = React.lazy(() => import('./components/admin/master/leave'));
const LeaveWFH = React.lazy(() => import('./components/user/attendance/leave-wfh'));
const LeaveWFHRequest = React.lazy(() => import('./components/user/attendance/leave-wfh-manager'));
const AttendanceRequest = React.lazy(() => import('./components/user/attendance/employee-attendance-manager'));
const ProjectList = React.lazy(() => import('./components/admin/projects/projects/index'));
const AddEditProject = React.lazy(() => import('./components/admin/projects/projects/AddEdit'));
const EmployeeProjects = React.lazy(() => import('./components/admin/projects/employeeProjects'));
const MyTimeSheet = React.lazy(() => import('./components/admin/projects/employeeProjects/MyTimeSheet'));
const TimeSheetTask = React.lazy(() => import('./components/admin/projects/timesheetTask'));
const ProjectTimeSheet = React.lazy(() => import('./components/admin/reports/ProjectTimeSheet/index'));
const RequestEmployeeAttendance = React.lazy(() => import('./components/admin/request_employee/document/index'));
const AdminEmployee = React.lazy(() => import('./components/admin/adminemployee/index'));
const AddEditProjectTask = React.lazy(() => import('./components/admin/projects/projectTask/AddEdit'));
const HourlyTimeSheet = React.lazy(() => import('./components/admin/reports/HourlyTimeSheet'));
const AttendanceReport = React.lazy(() => import('./components/admin/reports/Attendance'));
const Request = React.lazy(() => import("./components/user/request/reimbursement/index"));
const Reimbursement_HR = React.lazy(() => import("./components/user/request/reimbursement_hr/index"));
const AddReimbursement = React.lazy(() => import("./components/user/request/reimbursement/model/AddEditReimbursement"));
const ViewReimbursement = React.lazy(() => import("./components/user/request/reimbursement/view"));
const ViewHRReimbursement = React.lazy(() => import("./components/user/request/reimbursement_hr/view"));
const Document_HR = React.lazy(() => import("./components/user/request/document_hr/document/index"));
const Ticket_HR = React.lazy(() => import("./components/user/request/ticket_hr/index"));
const RequestEmployeeAttendanceHR = React.lazy(() => import('./components/admin/request_employee/hr_approved_request/index'));
const EmployeeDocuments = React.lazy(() => import('./components/admin/employee/EmployeeFileManager'));
const EmployeeAttendance = React.lazy(() => import("./components/admin/reports/EmployeeAttendance"));
const TeamAvailability = React.lazy(() => import("./components/admin/reports/TeamAvailability"));

const ViewEmployeeTax = React.lazy(() => import("./components/admin/employee/ViewTax"));


const EmployeePemRequest = React.lazy(() => import("./components/admin/pem/pemrequest/employeeRequest"));


const PemReview = React.lazy(() => import('./components/admin/pem/pemreview/index'));
const Profile = React.lazy(() => import("./components/admin/profile/index"));
const Document = React.lazy(() => import("./components/user/request/document/document/index"));


const ScrollToTop = () => {

	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

function App() {
	return (
		<Router>
			<Suspense fallback={
				<div id="loader-wrapper">
				<div id="loader">
					<div class="loader-ellips">
						<span class="loader-ellips__dot"></span>
						<span class="loader-ellips__dot"></span>
						<span class="loader-ellips__dot"></span>
						<span class="loader-ellips__dot"></span>
					</div>
				</div>
			</div>
		}>
				<ScrollToTop />
				<Routes>


					<Route path="/test" element={<Test />} />
					<Route path="/login" element={<Login />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/" element={<Navigate to="/login" />} />

					<Route path="/" element={<Layout />}>
						<Route path="admin-dashboard"
							element={
								<PrivateRoute requiredPermission="Dashboard">
									<AdminDashboard />
								</PrivateRoute>
							}
						/>
						<Route path="all-employees"
							element={
								<PrivateRoute role="admin" requiredPermission="All Employee">
									<EmployeeList />
								</PrivateRoute>
							}
						/>

						<Route path="add-employees"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<AddUser />
								</PrivateRoute>
							}
						/>

						<Route
							path="edit-employee/:id"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<AddUser />
								</PrivateRoute>
							}
						/>
						<Route
							path="my-attendance"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<MyAttendance />
								</PrivateRoute>
							}
						/>

						<Route
							path="/leave-wfh"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<LeaveWFH />
								</PrivateRoute>
							}
						/>
						<Route
							path="/leave-wfh-request"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<LeaveWFHRequest />
								</PrivateRoute>
							}
						/>
						<Route
							path="/employee-attendance-request"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<AttendanceRequest />
								</PrivateRoute>
							}
						/>
                        <Route
							path="/view-employee-tax/:employeeId"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<ViewEmployeeTax />
								</PrivateRoute>
							}
						/>

						<Route
							path="/all-employee-list"
							element={
								<PrivateRoute role="admin" requiredPermission="Employee">
									<AdminEmployee />
								</PrivateRoute>
							}
						/>


						<Route
							path="/addtional-employee-request"
							element={
								<RequestEmployeeAttendance />
							}
						/>

						<Route
							path="/addtional-employee-request-hr"
							element={
								<RequestEmployeeAttendanceHR />
							}
						/>

                        <Route
							path="/all-employee-documents"
							element={
                                <PrivateRoute role="admin" requiredPermission="Employee Documents">
								<EmployeeDocuments />
                                </PrivateRoute>
							}
						/>


						<Route
							path="/contract-employee-salary"
							element={
								<PrivateRoute role="admin" requiredPermission="Contractor Salary">
									<ContractEmployeeSalary />
								</PrivateRoute>
							}
						/>
						<Route
							path="/contract-employee"
							element={
								<PrivateRoute role="admin" requiredPermission="Contractor Employee">
									<ContractEmployeeList />
								</PrivateRoute>
							}
						/>

						{/* Project Routes Grouped */}
						<Route path="project" element={<PrivateRoute role="admin" requiredPermission="Project"><Outlet /></PrivateRoute>}>
							<Route path="list" element={<ProjectList />} />
							<Route path="add" element={<AddEditProject />} />
							<Route path="edit/:id" element={<AddEditProject />} />
							<Route path="my-projects" element={<EmployeeProjects />} />
							<Route path="my-time-sheet/:startDate/:endDate" element={<MyTimeSheet />} />
							<Route path="time-sheet-task/:pageType/:projectId" element={<TimeSheetTask />} />
							<Route path="tasks" element={<ProjectTask />} />
							<Route path="addTask" element={<AddEditProjectTask />} />
							<Route path="editTask/:id" element={<AddEditProjectTask />} />
						</Route>

						{/* Reports Routes Grouped */}
						<Route path="reports" element={<PrivateRoute role="admin" requiredPermission="Reports"><Outlet /></PrivateRoute>}>
							<Route path="project-time-sheet" element={<ProjectTimeSheet />} />
							<Route path="hourly-time-sheet" element={<HourlyTimeSheet />} />
							<Route path="attendance-report" element={<AttendanceReport />} />
							<Route path="view-time-sheet-details/:date/:user_id/:project_id" element={<TimeSheet />} />


							<Route path="employee-attendance-report" element={<EmployeeAttendance />} />
							<Route path="team-availability" element={<TeamAvailability />} />
						</Route>

						<Route path="subscriptions" element={<PrivateRoute role="admin" requiredPermission="All Subscriptions"><Outlet /></PrivateRoute>}>
							<Route path="all-subscriptions" element={<Subscription />} />
						</Route>


						{/* Master Routes Grouped */}
						<Route path="master" element={<PrivateRoute role="admin" requiredPermission="Master"><Outlet /></PrivateRoute>}>
							<Route path="designation" element={<Designation />} />
							<Route path="grade" element={<Grade />} />
							<Route path="team" element={<Team />} />
							<Route path="holiday" element={<Holiday />} />
							<Route path="leave" element={<Leave />} />
							<Route path="customer" element={<Customer />} />
							<Route path="project_technology" element={<ProjectTech />} />
							<Route path="projectBusinessArea" element={<ProjectBusinessArea />} />
							<Route path="projectBillability" element={<ProjectBillability />} />
							<Route path="projectPaymentType" element={<ProjectPaymentType />} />
							<Route path="projectPartner" element={<ProjectPartner />} />
							<Route path="projectType" element={<ProjectType />} />
						</Route>

						{/* Client Management Routes Grouped */}
						<Route path="clients" element={<PrivateRoute role="admin" requiredPermission="Client"><Outlet /></PrivateRoute>}>
							<Route index element={<Clients />} />
							<Route path="add" element={<ClientForm />} />
							<Route path="edit/:id" element={<ClientForm />} />
						</Route>

						<Route path="templates" element={<PrivateRoute role="admin" requiredPermission="All Templates"><Outlet /></PrivateRoute>}>
							<Route index element={<Templates />} />
							<Route path="add" element={<TemplateForm />} />
							<Route path="view/:id" element={<TemplateForm />} />
						</Route>

						<Route path="all-pem" element={<PrivateRoute role="admin" requiredPermission="All PEM"><Outlet /></PrivateRoute>}>
							<Route index element={<AllPem />} />
							<Route path="add" element={<AllPemForm />} />
							<Route path="reportview/:id" element={<AllPemReport />} />
						</Route>




						<Route
							path="pem/mygoals"
							element={
								<PrivateRoute role="admin" requiredPermission="My Goals">
									<MyGoalPemForm />
								</PrivateRoute>
							}
						/>




						<Route path="pem/request" element={<PrivateRoute role="admin" requiredPermission="PEM Request"><Outlet /></PrivateRoute>}>
							<Route index element={<PemRequests />} />
							{/* <Route path="add" element={<AllPemForm />} /> */}
							<Route path="view/:id" element={<PemRequestForm />} />

						</Route>
						<Route path="/pem-rating" element={<PemReview />} />

						<Route path="pem/employeerequest" element={<PrivateRoute role="admin" requiredPermission="Employee Request"><Outlet /></PrivateRoute>}>
							<Route index element={<EmployeePemRequest />} />
							<Route path=":type/:id" element={<PemRequestAppraiserForm />} />

						</Route>


						{/* Company Management Routes Grouped */}
						<Route path="companies" element={<PrivateRoute role="admin" requiredPermission="Company"><Outlet /></PrivateRoute>}>
							<Route index element={<Companies />} />
							<Route path="add" element={<CompanyForm />} />
							<Route path="edit/:id" element={<CompanyForm />} />
						</Route>

						<Route path="/contract-employee-salary"
							element={
								<PrivateRoute role="admin" requiredPermission="Contractor">
									<ContractEmployeeSalary></ContractEmployeeSalary>
								</PrivateRoute>
							}
						/>

						{/* Company Management Routes */}
						<Route path="rolespermission"
							element={
								<PrivateRoute role="admin" requiredPermission="Company">
									<RolesPermissions />
								</PrivateRoute>
							}
						/>

						<Route path="pem-questions"
							element={
								<PrivateRoute role="admin" requiredPermission="My Goals/Prefs">
									<PemQuestions />
								</PrivateRoute>
							}
						/>

						<Route path="admin" element={<PrivateRoute role="admin" requiredPermission="Assets"><Outlet /></PrivateRoute>}>
							<Route path="assetcategories" requiredPermission="Asset Categories" element={<AssetCategory />} />
							<Route path="assets" requiredPermission="All Assets" element={<Assets />} />
							<Route path="asset-request" requiredPermission="Request Asset" element={<AssetRequest />} />
							<Route path="asset-stats" requiredPermission="Request Stats" element={<AssetStats />} />
						</Route>




						<Route path="/contract-employee-salary"
							element={
								<PrivateRoute role="admin" requiredPermission="Contractor">
									<ContractEmployeeSalary></ContractEmployeeSalary>
								</PrivateRoute>
							}
						/>
						<Route path="user"
							element={
								<PrivateRoute role="user" requiredPermission="viewUserDashboard">
									<UserDashboard />
								</PrivateRoute>
							}
						/>

						<Route path="document" element={<PrivateRoute requiredPermission="Document HR"><Outlet /></PrivateRoute>}>
							<Route path="hr" element={<Document_HR />} />

						</Route>






						<Route path="reimbursement" element={<Request />} />
						<Route path="reimbursement/hr" element={<Reimbursement_HR />} />
						<Route path="/reimbursement/hr/view/:id" element={<ViewHRReimbursement />} />
						<Route path="reimbursement/add" element={<AddReimbursement />} />
						<Route path="/reimbursement/view/:id" element={<ViewReimbursement />} />
						<Route path="documents" element={<Document />} />
						<Route path="/ticket/details/hr" element={<Ticket_HR />} />


						<Route path="profile" element={<Profile />} />
						<Route path="asset-request" element={<AssetRequest />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Suspense>
		</Router>
	);
}

export default App;
