import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { updateEmployeeStatus, removeErrorMsg } from "../../../../redux/userSlice";
import { useDispatch, useSelector } from 'react-redux';
// import moment

import Alert from "../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const validationSchema = Yup.object().shape({
    termination_date: Yup.string().required("Termination date is required"),
});

function StatusUpdate({ show, handleClose, data }) {
    const { loading, error } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            termination_date:"",
        },
    });

    const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

    

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {        
        try {            
                values.id = data.id;
                values.termination_date = 
                await dispatch(updateEmployeeStatus(values));
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {       
        reset({
            termination_date: data?.termination_date ? new Date(data.termination_date) : ""
        });        
    }, [data, reset]);

    
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>Deactivate user</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert 
                        type="danger" 
                        message={error}
                        onClose={resetMsg} 
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Date of termination <span className="text-danger">*</span>
                        </label>
                        {/* <Controller
                            name="termination_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                selected={field.value ? new Date(field.value) : null} 
                                onChange={(date) => field.onChange(date)} 
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select a date"
                                />
                            )}
                            /> */}

                        <Controller
                            name="termination_date"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className={`form-control w-100 ${field.value ? '' : 'error-input'}`}
                                    selected={field.value ? parseISO(field.value) : null} // Parse ISO string to Date
                                    onChange={(date) => field.onChange(date ? format(date, "yyyy-MM-dd") : "")} // Format to "Y-m-d" for form state
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select a date"
                                />
                            )}
                        />
                        {errors.termination_date && <span className="text-danger">{errors.termination_date.message}</span>}
                    </div>
                    
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default StatusUpdate;
