import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';
import { omit } from 'lodash';

const initialState = {
  allUsers: null,
  userDetails: null,
  userDetailsSingle: null,

  employees: null,
  Request: null,
  RequestHR: null,
  stats_data: null,
  allCompanies: null,
  search: '',
  fields: ['name', 'designation'],
  pagination: null,
  loading: false,
  loadingsingle: false,
  companyLoading: false,
  error: null,
  success: null,
  message: null,
  documents:null,
  tax:null,
  personaldocuments:null,
  searchFilter: {
    team_id: "",
    reporting_to: "",
    status: 1,
    emp_grade_id: "",
    department: "",
    employment_type: "",
    searchText: "",

  },
  taxfile:null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },

    requestStartSingle: (state) => {
      state.loadingsingle = true;
      state.error = null;
      state.success = null;
    },

    requestSuccessSingle: (state, action) => {
      state.loadingsingle = false;
      state.error = null;
      state.success = null;
      state.userDetailsSingle = action.payload.data;

    },

    requestFailureSingle: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
      state.loadingsingle = false;
    },

    requestSuccess: (state, action) => {
      if (action.payload.type === 'companies') {
        state.allCompanies = action.payload.data;
      } else if (action.payload.type === 'details') {
        state.userDetails = action.payload.data;
      }
      else if (action.payload.type === 'get-request-access') {
        state.Request = action.payload.data;
      }
      else if (action.payload.type === 'get-request-access-hr') {
        state.RequestHR = action.payload.data;
      }

      else if (action.payload.type === 'employee-stats') {
        state.stats_data = action.payload.data;
      }
      else if (action.payload.type === 'documents') {
        state.documents = action.payload.data;
      }   else if (action.payload.type === 'personaldocuments') {
        state.personaldocuments = action.payload.data;
      }
      else if (action.payload.type === 'tax') {
        state.tax = action.payload.data;
      }
      else if (action.payload.type === 'tax-pdf') {
        //console.log(action.payload.data);
        state.taxfile = action.payload.data;
      }else {
        state.allUsers = action.payload.data;
        state.pagination = action.payload.pagination;
      }
      state.loading = false;
      state.loadingsingle = false;
    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
      state.loadingsingle = false;
    },
    added: (state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg: (state) => {
      state.error = null;
      state.success = null;
      state.message = null;

    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      state.searchFilter[key] = value;
    },
    resetFilters: (state) => {
      state.searchFilter = {
        team_id: "",
        reporting_to: "",
        status: "",
        emp_grade_id: "",
        department: "",
        employment_type: "",
        searchText: "",
      };
    },
  },
});

export const {
  requestStart,
  requestStartSingle,
  requestSuccess,
  requestSuccessSingle,
  requestFailureSingle,
  requestFailure,
  added,
  removeErrorMsg,
  setLayout,
  setFilter,
  resetFilters
} = userSlice.actions;

export default userSlice.reducer;

export const getAllUser = ({ page, per_page }) => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().user;
  let searchText = searchFilter?.searchText || '';

  const { searchText: _, ...searchParams } = searchFilter;

  try {
    const response = await axiosInstance.post(
      `/all-users?page=${page}&per_page=${per_page}&search=${searchText}&fields[]=name&fields[]=emp_id&fields[]=status`,
      searchParams // Only send the remaining search parameters
    );

    const { data, status, message, pagination } = response.data;

    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'user' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};
export const getAllUserData = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().user;
  let searchText = searchFilter?.searchText || '';
  const { searchText: _, ...searchParams } = searchFilter;

  try {
    let allUsers = [];
    let currentPage = 1;
    let totalPages = 1;

    // Fetch all pages
    do {
      const response = await axiosInstance.post(
        `/all-users?page=${currentPage}&per_page=100&search=${searchText}&fields[]=name&fields[]=emp_id&fields[]=status`,
        searchParams
      );

      const { data, status, pagination } = response.data;

      if (status) {
        allUsers = [...allUsers, ...data];
        totalPages = pagination.total_pages;
        currentPage++;
      } else {
        dispatch(requestFailure('Failed to fetch data'));
        return;
      }
    } while (currentPage <= totalPages);

    dispatch(requestSuccess({ data: allUsers, type: 'user' }));
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};


export const getUserDetails = (id) => async (dispatch) => {
  dispatch(requestStart());


  try {
    const response = await axiosInstance.get(`/user-details/` + id);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'details' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getSingleUserDetails = (id) => async (dispatch) => {

  dispatch(requestStartSingle());

  try {
    const response = await axiosInstance.get(`/user-details/` + id);
    const { data, status, message, pagination } = response.data;
    if (status) {
      dispatch(requestSuccessSingle({ data, pagination, type: 'details' }));
    } else {
      dispatch(requestFailureSingle(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailureSingle(error.message || 'Failed to fetch data'));
  }
};

export const getAllCompany = () => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/outsourced-companies?page=1&per_page=100`);
    const { data, status, message, pagination } = response.data;

    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, pagination, type: 'companies' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const addCompany = (data) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/add-outsourced-company`, data);

    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add designation'));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to add designation'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to add designation'));
    }
  }
};

export const addUser = (userData) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/user/add`, userData);

    const { status, message } = response.data;
    if (status) {
      dispatch(added({ message }));
    } else {
      console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add user'));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to add user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to add user'));
    }
  }
};

export const exportUser = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().user;
  try {
    const response = await axiosInstance.get(`/export-users?&search=${searchFilter.searchText}&fields[]=name&fields[]=emp_id`, {
      params: searchFilter,
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.xlsx'); // Set the file name
    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up

    // Dispatch success action if needed
    dispatch(added({ message: 'Export successful!' }));

  } catch (error) {
    // Handle errors here
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(' ');
      dispatch(requestFailure(errorMessage || 'Failed to export user'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to export user'));
    }
  }
};

export const updateEmployeeStatus = (userData) => async (dispatch) => {
  dispatch(requestStart());

  try {
    console.log(userData, 'data')
    const response = await axiosInstance.post(`/user/status-update`, userData);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to update designation'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to update designation'));
  }
};

export const updateUserRole = (userData) => async (dispatch) => {
  dispatch(requestStart());

  try {

    const response = await axiosInstance.post(`/user/roles-update`, userData);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to update role'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to update role'));
  }
};



export const deleteUser = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.delete(`/user-delete/${id}`);
    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to delete user'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to delete data'));
  }
};

export const updateProfile = (id, formDataToSend) => async (dispatch) => {
  // dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/user/profile-update/${id}`, formDataToSend, {
      headers: {
        "Content-Type": "multipart/form-data", // Important for FormData
      },
    });

    const { data, status, message } = response.data;

    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to update profile'));
    }

    return response; // Ensure the action returns the response
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to update profile'));
    return error.response;
  }
};


export const RequestEmploeeAccess = (userData) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/request-employee-access`, userData);

    const { status, message } = response.data;

    if (status) {
      // Dispatch success with message if status is true
      dispatch(added({ message }));
      return { status: true, message }; // Return success status and message
    } else {
      console.log('Error:', message);
      dispatch(requestFailure(message || 'Failed to add user'));
      return { status: false, message: message || 'Failed to add user' }; // Return failure status and message
    }
  } catch (error) {
    let errorMessage = 'Failed to add user';

    if (error.response && error.response.data && error.response.data.errors) {
      // Handle validation errors
      const validationErrors = error.response.data.errors;
      errorMessage = Object.values(validationErrors).flat().join(' ') || errorMessage;
    } else if (error.response && error.response.data && error.response.data.message) {
      // Handle server errors with a message
      errorMessage = error.response.data.message || errorMessage;
    } else {
      // Handle other types of errors
      errorMessage = error.message || errorMessage;
    }

    dispatch(requestFailure(errorMessage));
    return { status: false, message: errorMessage }; // Return failure status and error message
  }
};

export const UpdateRequestStatus = (payload, status) => async (dispatch) => {
  console.log("Payload received:", payload, status);

  dispatch(requestStart());

  try {
    const response = await axiosInstance.post(
      `/request-employee-access-hr-status-change/${payload}`,
      { status: status }
    );

    const { success, message } = response.data;

    if (success) {
      dispatch(added({ message }));
      return { status: true, message };
    } else {
      dispatch(requestFailure(message || "Failed to update status"));
      return { status: false, message: message || "Failed to update status" };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || error.message || "Failed to update status";

    console.error("Error caught:", errorMessage);
    dispatch(requestFailure(errorMessage));
    return { status: false, message: errorMessage };
  }
};



export const GetRequestEmploeeAccess = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/get-request-access?page=${page}&per_page=${per_page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });

    // Extract data and meta from the response
    const { data, meta } = response.data; // Assuming response.data contains both data and meta

    if (data) {
      dispatch(requestSuccess({ data, type: 'get-request-access' }));
    } else {
      dispatch(requestFailure('No data found'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const GetRequestEmploeeAccessHr = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/get-request-access-hr?page=${page}&per_page=${per_page}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'code': 'super',
      },
    });

    // Extract data and meta from the response
    const { data, meta } = response.data; // Assuming response.data contains both data and meta

    if (data) {
      dispatch(requestSuccess({ data, type: 'get-request-access-hr' }));
    } else {
      dispatch(requestFailure('No data found'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getemployeeStats = () => async (dispatch, getState) => {
  dispatch(requestStart());
  const { searchFilter } = getState().user;
  try {
    const response = await axiosInstance.post(
      `/employee-stats`,
      searchFilter
    );

    const { data, status, message } = response.data;

    if (status) {
      dispatch(requestSuccess({ data, type: 'employee-stats' }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const getAllContractEmployees = ({ page, per_page }) => async (dispatch, getState) => {
    dispatch(requestStart());
    const { searchFilter } = getState().user;
    let searchText = searchFilter?.searchText || '';

    const { searchText: _, ...searchParams } = searchFilter;

    try {

      const response = await axiosInstance.post(
        `/all-contract-employees?page=${page}&per_page=${per_page}&search=${searchText}&fields[]=name&fields[]=emp_id&fields[]=status`,
        searchParams // Only send the remaining search parameters
      );

      const { data, status, message, pagination } = response.data;

      if (status) {
        dispatch(requestSuccess({ data, pagination, type: 'user' }));
      } else {
        dispatch(requestFailure(message || 'Failed to fetch data'));
      }
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to fetch data'));
    }
  };

  export const uploadUserDocument = (formDataToSend) => async (dispatch) => {
     dispatch(requestStart());
    try {
      const response = await axiosInstance.post(`/user-document`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for FormData
        },
      });

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
      } else {
        dispatch(requestFailure(message || 'Failed to upload doc'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to upload doc'));
      return error.response;
    }
  };

  export const getUserDocuments = (employeeId,filter="") => async (dispatch) => {
    // dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/user-documents/${employeeId}?filter=${filter}`);

      const { status, message, data } = response.data;

      if (status) {
        dispatch(requestSuccess({ data, type: 'documents' }));
      } else {
        dispatch(requestFailure(message || 'Failed to fetch data'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to get docs'));
      return error.response;
    }
  };

  export const deleteUserDocument = (id) => async (dispatch) => {
    // dispatch(requestStart());
    try {

      const response = await axiosInstance.delete(`/user-documents/${id}`);

      const { status, message } = response.data;

      if (status) {
        dispatch(added({ message }));
      } else {
        dispatch(requestFailure(message || 'Failed to delete doc'));
      }

      return response; // Ensure the action returns the response

    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to delete doc'));
      return error.response;
    }
  };


  export const uploadPersonalDocument = (formDataToSend) => async (dispatch) => {
    dispatch(requestStart());
   try {
     const response = await axiosInstance.post(`/user-personal-document`, formDataToSend, {
       headers: {
         "Content-Type": "multipart/form-data", // Important for FormData
       },
     });

     const { status, message } = response.data;

     if (status) {
       dispatch(added({ message }));
     } else {
       dispatch(requestFailure(message || 'Failed to upload doc'));
     }

     return response; // Ensure the action returns the response
   } catch (error) {
     dispatch(requestFailure(error.message || 'Failed to upload doc'));
     return error.response;
   }
 };

 export const getUserPersonalDocuments = (employeeId,filter="") => async (dispatch) => {
   // dispatch(requestStart());
   try {
     const response = await axiosInstance.get(`/user-personal-documents/${employeeId}?filter=${filter}`);

     const { status, message, data } = response.data;

     if (status) {
       dispatch(requestSuccess({ data, type: 'personaldocuments' }));
     } else {
       dispatch(requestFailure(message || 'Failed to fetch data'));
     }

     return response; // Ensure the action returns the response
   } catch (error) {
     dispatch(requestFailure(error.message || 'Failed to get docs'));
     return error.response;
   }
 };

 export const deleteUserPersonalDocument = (userData) => async (dispatch) => {
   // dispatch(requestStart());
   try {

     const response = await axiosInstance.post(`/delete-personal-document`,userData);

     const { status, message } = response.data;

     if (status) {
       dispatch(added({ message }));
     } else {
       dispatch(requestFailure(message || 'Failed to delete doc'));
     }

     return response; // Ensure the action returns the response

   } catch (error) {
     dispatch(requestFailure(error.message || 'Failed to delete doc'));
     return error.response;
   }
 };


 export const saveUserTax = (formDataToSend) => async (dispatch) => {
    dispatch(requestStart());
   try {
     const response = await axiosInstance.post(`/save-tax`, formDataToSend);

     const { status, message } = response.data;

     if (status) {
       dispatch(added({ message }));
     } else {
       dispatch(requestFailure(message || 'Tax regime saved successfully'));
     }

     return response; // Ensure the action returns the response
   } catch (error) {
     dispatch(requestFailure(error.message || 'Failed to save tax regime'));
     return error.response;
   }
 };

 export const saveUserTaxes = (formDataToSend) => async (dispatch) => {
  dispatch(requestStart());
 try {
   const response = await axiosInstance.post(`/save-taxes`, formDataToSend);

   const { status, message } = response.data;

   if (status) {
     dispatch(added({ message }));
   } else {
     dispatch(requestFailure(message || 'Tax regime saved successfully'));
   }

   return response; // Ensure the action returns the response
 } catch (error) {
   dispatch(requestFailure(error.message || 'Failed to save tax regime'));
   return error.response;
 }
};

export const saveUserTaxes80C = (formDataToSend) => async (dispatch) => {
  dispatch(requestStart());
 try {
   const response = await axiosInstance.post(`/save-tax80c`, formDataToSend);

   const { status, message } = response.data;

   if (status) {
     dispatch(added({ message }));
   } else {
     dispatch(requestFailure(message || 'Tax regime saved successfully'));
   }

   return response; // Ensure the action returns the response
 } catch (error) {
   dispatch(requestFailure(error.message || 'Failed to save tax regime'));
   return error.response;
 }
};

export const saveHraDeclaration = (formDataToSend) => async (dispatch) => {
  dispatch(requestStart());
 try {
   const response = await axiosInstance.post(`/save-hra-declaration`, formDataToSend);

   const { status, message } = response.data;

   if (status) {
     dispatch(added({ message }));
   } else {
     dispatch(requestFailure(message || 'Tax regime saved successfully'));
   }

   return response; // Ensure the action returns the response
 } catch (error) {
   dispatch(requestFailure(error.message || 'Failed to save tax regime'));
   return error.response;
 }
};

export const saveUserTaxesOtherDeduction = (formDataToSend) => async (dispatch) => {
  dispatch(requestStart());
 try {
   const response = await axiosInstance.post(`/save-tax-other-deduction`, formDataToSend);

   const { status, message } = response.data;

   if (status) {
     dispatch(added({ message }));
   } else {
     dispatch(requestFailure(message || 'Tax regime saved successfully'));
   }

   return response; // Ensure the action returns the response
 } catch (error) {
   dispatch(requestFailure(error.message || 'Failed to save tax regime'));
   return error.response;
 }
};
 export const getUserTax = (employeeId) => async (dispatch) => {
   // dispatch(requestStart());
   try {
     const response = await axiosInstance.get(`/user-tax/${employeeId}`);

     const { status, message, data } = response.data;

     if (status) {
       dispatch(requestSuccess({ data, type: 'tax' }));
     } else {
       dispatch(requestFailure(message || 'Failed to fetch data'));
     }

     return response; // Ensure the action returns the response
   } catch (error) {
     dispatch(requestFailure(error.message || 'Failed to get docs'));
     return error.response;
   }
 };

 export const getUserTaxPdf = (employeeId) => async (dispatch) => {
    // dispatch(requestStart());
    try {
      const response = await axiosInstance.get(`/user-tax-pdf/${employeeId}`,{
        responseType: 'blob' // Important to set the correct response type
    });

    //   console.log(response);
      const data = response.data;
    //   console.log(response.data);


      if (data) {
        dispatch(requestSuccess({ data, type: 'tax-pdf' }));
      } else {
        dispatch(requestFailure('Failed to fetch data'));
      }

      return response; // Ensure the action returns the response
    } catch (error) {
      dispatch(requestFailure(error.message || 'Failed to get docs'));
      return error.response;
    }
  };





