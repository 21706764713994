import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../Breadcrumbs";
import DeleteModal from "./model/DeleteModal";
import SearchBox from "./SearchBox";
import { getAllUser, deleteUser, updateEmployeeStatus, exportUser, getemployeeStats } from "../../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import StatusUpdate from "./model/StatusUpdate";
import RoleSetting from "./model/RoleSetting";
import BarChartGrade from "./BarChart";
import PieChart from "./PieChart";
import BarChartDepartment from "./BarChartDepartment";
import PieChartWorkModel from "./PieChartWorkModel";

const EmployeeList = () => {

  const dispatch = useDispatch();
  const { allUsers, pagination, loading, success, stats_data } = useSelector((state) => state.user);
  const [viewMode, setViewMode] = useState("list"); // Default is "list"
  const [paginationState, setPaginationState] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(getAllUser({ page: paginationState.current, per_page: paginationState.pageSize }));
    dispatch(getemployeeStats())


  }, [dispatch, paginationState]);

  console.log(stats_data, 'stats_data');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const [userDetails, setUserDetails] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showModalRole, setShowModalRole] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleShowRole = () => setShowModalRole(true);
  const handleCloseRole = () => setShowModalRole(false);

  useEffect(() => {
    if (success) {
      dispatch(getAllUser({ page: paginationState.current, per_page: paginationState.pageSize }));

      handleClose();
    }
  }, [dispatch, paginationState, success]);


  const userElements = allUsers?.map((row, index) => ({
    ind: ((paginationState.current - 1) * paginationState.pageSize) + index + 1,
    key: index,
    id: row.id,
    emp_code: row.emp_id ?? "N/A",
    name: row.name,
    email: row.email,
    gender: row.gender,
    team: row.team ? row.team.description : 'n/a',
    reporting_manager: row.manager ? row.manager.name : 'n/a',
    mobile: row.mobile_number,
    joining_date: row.joining_date,
    status: row.status === "1" ? 'Active' : 'Inactive',
    profile_pic: row.profile_pic,
    grade: row.grade ? row.grade.name : "N/A",
    department: row.department,
    employment_type: row.employment_type,
    role_id: row.role_id,

  }));

  const changeStatus = async (user) => {
    if (user.status === 'Active') {
      setUserDetails(user)
      handleShow();
    } else {
      await dispatch(updateEmployeeStatus(user));
    }
  }


  const changeRole = async (user) => {

    setUserDetails(user)
    handleShowRole();

  }

  const columns = [
    {
      title: "#",
      dataIndex: "ind",
      className: "text-start",
      sorter: (a, b) => a.key - b.key,
      width: "5%",
      render: (text) => (
        <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
          {text}
        </div>
      ),
    },
    {
      title: "Employee Id",
      dataIndex: "emp_code",
      sorter: (a, b) => a.emp_code.length - b.emp_code.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        // <span className="table-avatar">
        //   <Link to="/profile" className="avatar">
        //     <img alt="" src={record.profile_pic} />
        //   </Link>
        //   <Link to="/profile">
        <span>{record.name}</span>
        //   </Link>
        // </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Manager",
      dataIndex: "reporting_manager",
      sorter: (a, b) => a.reporting_manager.length - b.reporting_manager.length,
    },
    {
      title: "Team",
      dataIndex: "team",
      sorter: (a, b) => a.team.length - b.team.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      className: "text-capitalize",
      sorter: (a, b) => a.gender.length - b.gender.length,
      render: (text, user) => (

        <span>{text}</span>
      ),
    },
    {
      title: "Grade",
      dataIndex: "grade",
      sorter: (a, b) => a.grade.length - b.grade.length,
    },
    {
      title: "Work Model",
      dataIndex: "department",
      className: "text-uppercase",
      sorter: (a, b) => a.department.length - b.department.length,
    },
    {
      title: "Employment Type",
      dataIndex: "employment_type",
      className: "text-capitalize",
      sorter: (a, b) => a.employment_type.length - b.employment_type.length,
    },

    {
      title: "Joining Date",
      dataIndex: "joining_date",
      sorter: (a, b) => a.joining_date.length - b.joining_date.length,
    },

    {
      title: "Status",
      dataIndex: "Status",
      render: (text, user) => (
        <div className="dropdown">
          <Link
            to="#"
            className="btn btn-white btn-sm btn-rounded "
            onClick={(e) => changeStatus(user)}
          >
            <i
              className={
                user.status === "Active"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />
            {user.status}
          </Link>

        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      sorter: true,
      render: (text, user) => (
        <div className="dropdown dropdown-action text-end">
          <div className="dropdown dropdown-action text-end me-30">
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => changeRole(user)}
            >
              <i className="la la-key m-r-5" />
            </Link>

            <Link
              className="dropdown-item1 m-r-10"
              to={'/edit-employee/' + user.id}
            >
              <i className="fa fa-pencil m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to={'/view-employee-tax/' + user.id}
            >
              <i className="fa fa-file-text m-r-5" />
            </Link>
            <Link
              className="dropdown-item1 m-r-10"
              to="#"
              onClick={() => deleteModal(user)}
            >
              <i className="fa fa-trash m-r-5" />
            </Link>
          </div>
          {/* </div> */}
        </div>
      ),
    },
  ];
  const deleteModal = (data) => {
    setUserDetails(data);
    handleDeleteShow()
  }

  const deleteUserData = (data) => {
    handleDeleteClose();
    dispatch(deleteUser(data.id));
  }

  const onSearch = () => {
    setPaginationState({ current: 1, pageSize: paginationState.pageSize })
    dispatch(getAllUser({ page: 1, per_page: paginationState.pageSize }));
  }

  const exportEmployee = () => {
    dispatch(exportUser());

  }
  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}

          <div className="page-header">
            <div className="row align-items-center">
              <Breadcrumbs
                maintitle="Employee"
                title="Dashboard"
                subtitle="Employee"
              />

              <div className="col-md-6 text-end">
                <button
                  className="btn btn-outline-secondary me-3 mb-2"
                  onClick={() => {
                    const newViewMode = viewMode === "list" ? "chart" : "list";
                    setViewMode(newViewMode);
                  }}
                >
                  {viewMode === "list" ? (
                    <>
                      <i className="fa fa-chart-bar me-1" />
                      Chart View
                    </>
                  ) : (
                    <>
                      <i className="fa fa-list me-1" />
                      List View
                    </>
                  )}
                </button>

                {!loading ? (

                  <Link
                    to="#"
                    class="btn btn-success me-3 mb-2"
                    onClick={(e) => { exportEmployee() }}
                    fdprocessedid="as7mtr"
                  >
                    <i className="las la-file-export" /> Export
                  </Link>
                ) : (
                  <Link
                    to="#"
                    class="btn btn-success me-3 mb-2"
                    fdprocessedid="as7mtr"
                  >
                    <i className="las la-file-export" /> Exporting...
                  </Link>
                )}


                <Link
                  to="/add-employees"
                  class="btn btn-primary  me-3 mb-2"
                  on
                  fdprocessedid="as7mtr"
                >
                  {" "}
                  <i className="fa fa-plus" /> Add Employee
                </Link>
              </div>
            </div>
          </div>

          <SearchBox onSearch={onSearch} />
          {viewMode === "chart" ? (
            <div>
              <div className="row">
                <div className="col-md-8">
                  <BarChartDepartment dataa={stats_data?.deptObj} />
                </div>
                <div className="col-md-4">
                  <PieChartWorkModel dataa={stats_data?.genObj} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <BarChartGrade dataa={stats_data?.gradeObj} />
                </div>
                <div className="col-md-4">
                  <PieChart dataa={stats_data?.wmObj} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <Table
                      style={{ overflowX: "auto" }}
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        userElements?.length > 0
                          ? userElements?.map((designation) => ({
                            ...designation,
                            key: designation.id,
                          }))
                          : []
                      }
                      pagination={{
                        current: paginationState.current,
                        pageSize: paginationState.pageSize,
                        total: pagination?.total,
                        onChange: (page, pageSize) => setPaginationState({ current: page, pageSize }),
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <DeleteModal show={showDeleteModal} handleClose={handleDeleteClose} data={userDetails} onDelete={deleteUserData} name="Delete Employee" />
        <StatusUpdate show={showModal} handleClose={handleClose} data={userDetails} />
        <RoleSetting show={showModalRole} handleClose={handleCloseRole} data={userDetails} />
      </div>
    </div>
  );
};

export default EmployeeList;
