import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addDesignation, removeErrorMsg , updateDesignation, getAllDesignations} from "../../../../../redux/designationSlice";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from "../../../../../helpers/AlertMessage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    designation: Yup.string().required("Designation is required"),
});

function AddEditDesignation({ show, handleClose, designation }) {
    const { loading, error, success } = useSelector((state) => state.designation);
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            designation:"",
        },
    });

    const MySwal = withReactContent(Swal);

    const showAlert = (config) => {
        MySwal.fire(config);
    };

    const resetMsg = () => {
        dispatch(removeErrorMsg());
    };

    const onSubmit = async (values) => {
        try {
            
            if(designation){
                values.id = designation.id;
                await dispatch(updateDesignation(values));

            }else{
                await dispatch(addDesignation(values));

            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if(designation){
            reset({
                name: designation?.name || "",
                designation: designation?.designation || "",
            });
        } else{
            reset({
                name: "",
                designation: "",
            });
        }
        
    }, [designation, reset]);

    
    const handleModalClose = () => {
        resetMsg();
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} centered dialogClassName="modal custom-modal">
            <Modal.Header>
                <Modal.Title>{designation ? 'Edit Designation' : 'Add Designation'}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleModalClose}>
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert 
                        type="danger" 
                        message={error}
                        onClose={resetMsg} 
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.name ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.name && <span className="text-danger">{errors.name.message}</span>}
                    </div>
                    <div className="input-block mb-3">
                        <label className="col-form-label">
                            Designation <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="designation"
                            control={control}
                            render={({ field }) => (
                                <input
                                    className={`form-control ${errors.designation ? "error-input" : ""}`}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                        {errors.designation && <span className="text-danger">{errors.designation.message}</span>}
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                        {!loading ? (
                            <button className="btn btn-primary" type="submit">
                                Save Changes
                            </button>
                        ) : (
                            <button type="button" className="btn btn-primary" disabled>
                                <i className="fas fa-spinner fa-spin me-2" />
                                Processing...
                            </button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditDesignation;
