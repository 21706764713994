import React from "react";

const LeaveStats = ({ leaveStats, workingDays }) => {
    const statsSummary = [
        {
            key: "total",
            colorClass: "text-primary",
        },
        {
            key: "taken",
            colorClass: "text-pink",
        },
        {
            key: "pending",
            colorClass: "text-purple",
        },
        {
            key: "remaining",
            colorClass: "text-warning",
        },
    ];

    return (
        <>
            {/* Dynamic Leave Stats - Exclude Remaining */}
            {leaveStats.map((stat) =>

                statsSummary
                    .map((summary) => (

                        <div className="col-md-6" key={`${stat.leave_type_id}-${summary.key}`}>
                            <div className="attendance-details">
                                <h4 className={summary.colorClass}>{stat[summary.key]?.value || 0}</h4>
                                <p>{stat[summary.key]?.label || summary.key}</p>
                            </div>

                        </div>

                    ))

            )}

            {/* Dynamic Leave Stats - Only Remaining */}
            {/* {leaveStats.map((stat) =>
                statsSummary
                    .filter((summary) => summary.key === "remaining")
                    .map((summary) => (
                        <div className="col-md-6" key={`${stat.leave_type_id}-${summary.key}`}>
                            <div className="attendance-details">
                                <h4 className={summary.colorClass}>{stat[summary.key]?.value || 0}</h4>
                                <p>{stat[summary.key]?.label || summary.key}</p>
                            </div>
                        </div>
                    ))
            )} */}

            {/* Static Working Days */}
            <div className="col-md-6">
                <div className="attendance-details">
                    <h4 className="text-info">{workingDays}</h4>
                    <p>Working Days</p>
                </div>
            </div>
        </>
    );
};

export default LeaveStats;
