import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from './axiosInstance';

const initialState = {
  allData: null,
  search:'',
  pagination:null,
  loading: false,
  error: null,
  success:null,
  message:null
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    requestSuccess: (state, action) => {
      state.allData = action.payload.data;
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    requestFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = null;
    },
    added:(state, action) => {
      state.error = null;
      state.success = action.payload.message;
      state.message = action.payload.message;
      state.loading = false;
    },
    removeErrorMsg:(state)=>{
      state.error = null;
      state.success = null;
      state.message = null;

    },
  },
});

export const { 
  requestStart, 
  requestSuccess, 
  requestFailure,
  added ,
  removeErrorMsg
} = teamSlice.actions;

export default teamSlice.reducer;

export const getAll = ({ page, per_page, search }) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.get(`/teams?page=${page}&per_page=${per_page}&search=${search}&fields[]=team&fields[]=description`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const { data, status, message, pagination } = response.data;

    console.log(response.data)
    if (status) {
      dispatch(requestSuccess({ data, pagination }));
    } else {
      dispatch(requestFailure(message || 'Failed to fetch data'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to fetch data'));
  }
};

export const add = (designationData) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.post(`/teams`, designationData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Use Bearer token format
      },
    });

    const { status, message } = response.data;
    if (status) {
      dispatch(added({message}));
    } else {
        console.log('error', message);
      dispatch(requestFailure(message || 'Failed to add designation'));
    }
  } catch (error) {

    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors; // Get the validation errors
      const errorMessage = Object.values(validationErrors)
        .flat()
        .join(', '); // Flatten the error messages
      
      dispatch(requestFailure(errorMessage || 'Failed to add designation'));
    } else {
      dispatch(requestFailure(error.message || 'Failed to add designation'));
    }
  }
};

export const update = (designationData) => async (dispatch) => {
  dispatch(requestStart());
  
  try {
    const response = await axiosInstance.put(`/teams/${designationData.id}`, designationData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Use Bearer token format
      },
    });

    const { data, status, message } = response.data;
    console.log(data); // Log the received data for debugging

    if (status) {
      dispatch(added({ message, data })); // Include data if needed
      // dispatch(getAllDesignations({ page: 1, per_page: 10 }));
    } else {
      dispatch(requestFailure(message || 'Failed to update designation'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to update designation'));
  }
};

export const deleteTeam = (id) => async (dispatch) => {
  dispatch(requestStart());
  try {
    const response = await axiosInstance.delete(`/teams/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const { data, status, message } = response.data;
    if (status) {
      dispatch(added({ message, data }));
    } else {
      dispatch(requestFailure(message || 'Failed to delete designation'));
    }
  } catch (error) {
    dispatch(requestFailure(error.message || 'Failed to delete data'));
  }
};



