
export const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
];

export const status = [
    { value: "", label: "All" },
    { value: "1", label: "Active" },
    { value: "0", label: "In active" }
];

export const workModel = [
    { value: "wfh", label: "WFH" },
    { value: "wfo", label: "WFO" },
    { value: "hybrid", label: "Hybrid" },
    { value: "other", label: "Other" },
];

export const employmentType = [
    { value: "contract", label: "Contract" },
    { value: "employee", label: "Employee" },
];

export const leaveStatusOptions = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
    { value: "revoked", label: "Revoked" },
];

export const attendanceStatusOptions = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
];

export const requestForOption = [
    { value: "leave", label: "Leave" },
    { value: "wfh", label: "WFH" },
    { value: "comp_off", label: "Comp. Off" },
];

export const salaryType = [
    { value: "hourly", label: "hourly" },
    { value: "lumpsum", label: "lumpsum" },
];

export const whichHalfOption = [
    { value: "first_half", label: "First Half" },
    { value: "second_half", label: "Second Half" },
];

export const presentType = [
    { value: "wfo", label: "WFO" },
    { value: "wfh", label: "WFH" },

    // { value: "extra-day-fo", label: "Extra Day From Office" },
    // { value: "extra-day-fh", label: "Extra Day From Home" },
];

export const presentDayType = [
    { value: "half_day", label: "Half Day" },
    { value: "full_day", label: "Full Day" },
    // { value: "extra-day-fo", label: "Extra Day From Office" },
    // { value: "extra-day-fh", label: "Extra Day From Home" },
];
export const countryCode = [
    { value: "+91", label: "+91" },
    { value: "+1", label: "+1" },
];

export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  export const taskStatus = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
];

